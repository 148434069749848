import React from 'react';
import {getChannelUngroupingEnabled} from 'profile/store/selectors';
import {useSelector} from 'react-redux';
import SalesforceLoginSandbox from 'channels/components/fields/SalesforceLoginSandbox';
import SelectChannelDimOrTag from 'channels/components/fields/SelectChannelDimOrTag';
import Url from 'channels/components/fields/Url';
import TimeZone from 'channels/components/fields/Timezone';
import Members from 'channels/components/fields/Members';
import Email from 'channels/components/fields/Email';
import User from 'channels/components/fields/User';
import Password from 'channels/components/fields/Password';
import JiraUser from 'channels/components/fields/JiraUser';
import AccessToken from 'channels/components/fields/AccessToken';
import SnowTable from 'channels/components/fields/SnowTable';
import IntegrationApiKey from 'channels/components/fields/IntegrationApiKey';
import ServiceKey from 'channels/components/fields/ServiceKey';
import Topic from 'channels/components/fields/Topic';
import Authentication from 'channels/components/fields/Authentication';
import UrlDropdown from 'channels/components/fields/UrlDropdown';
import JiraProjectId from 'channels/components/fields/JiraProjectId';
import JiraIssueType from 'channels/components/fields/JiraIssueType';
import Headers from 'channels/components/fields/Headers';
import UngroupAlertChannel from 'channels/components/fields/UngroupAlertChannel';
import ChannelDataName from 'channels/components/fields/ChannelDataName';
import ChannelName from 'channels/components/fields/ChannelName';
import {CHANNEL_TYPES} from 'channels/constants/channelTypes';
import Test from './Test';
import AccessGroups from './AccessGroups';

const accessGroupsRender = (channelId, onUpdateContainer) => (
  <div className="display_flex mb_3">
    <div className="flexBasis_50 mr_3">
      <AccessGroups channelId={channelId} onUpdateContainer={onUpdateContainer} />
    </div>
    <div className="flexBasis_50" />
  </div>
);

const StandartChannelSettings = ({
  channelType,
  channelId,
  onUpdateContainer,
  isRbacEnabled,
  isChannelUngrouped,
}: {
  channelType: string,
  channelId: string,
  onUpdateContainer: Function,
  isRbacEnabled: Boolean,
  isChannelUngrouped: Boolean,
}) => {
  if (channelType === CHANNEL_TYPES.SLACKAPP && !channelId) {
    return (
      <>
        <div className="mb_3">A new channel must be created by an admin of your Slack workspace within Slack.</div>
        <div className="mb_3">
          Once created, the channel must be linked to alerts to start receiving alert triggers.
        </div>
      </>
    );
  }
  let channelConnectionInput;
  if (channelType === CHANNEL_TYPES.PAGERDUTY) {
    channelConnectionInput = <ServiceKey />;
  } else if (channelType === CHANNEL_TYPES.SNS) {
    channelConnectionInput = <Topic />;
  } else {
    channelConnectionInput = <Url channelType={channelType} />;
  }

  let channelName;
  let channelDataName;
  if (channelType === CHANNEL_TYPES.SLACK || channelType === CHANNEL_TYPES.MATTERMOST) {
    channelName = <ChannelName label="Anodot channel name" />;
    const channelTypeLabel = channelType.charAt(0).toUpperCase() + channelType.slice(1).toLowerCase();
    channelDataName = <ChannelDataName channelName={channelTypeLabel} />;
  } else {
    channelName = <ChannelName />;
  }

  const isChannelSupportUngrouping = [
    CHANNEL_TYPES.SLACKAPP,
    CHANNEL_TYPES.MSTEAMS,
    CHANNEL_TYPES.MSTEAMSWORKFLOW,
    CHANNEL_TYPES.PAGERDUTY,
  ].includes(channelType);

  const isChannelUngroupingEnabled = useSelector(getChannelUngroupingEnabled);

  return (
    <>
      <div className="mb_2-5">{channelName}</div>
      {channelDataName && <div className="mb_2-5">{channelDataName}</div>}

      <div className="mb_2-5">{channelConnectionInput}</div>
      <div className="display_flex mb_3">
        <div className="flexBasis_50 mr_3">
          <TimeZone />
        </div>
        <div className="flexBasis_50" />
      </div>
      {isRbacEnabled && accessGroupsRender(channelId, onUpdateContainer)}
      {isChannelUngroupingEnabled && isChannelSupportUngrouping && (
        <div className="mb_2-5">
          <UngroupAlertChannel channelType={channelType} isChannelUngrouped={isChannelUngrouped} />
        </div>
      )}

      <div className="mb_4">
        <Test channelType={channelType} />
      </div>
    </>
  );
};

const EmailChannelSettings = ({
  channelType,
  channelId,
  onUpdateContainer,
  isRbacEnabled,
  isChannelUngrouped,
}: {
  channelType: string,
  channelId: string,
  onUpdateContainer: Function,
  isRbacEnabled: Boolean,
  isChannelUngrouped: Boolean,
}) => {
  const isChannelUngroupingEnabled = useSelector(getChannelUngroupingEnabled);

  return (
    <>
      <div className="mb_2-5">
        <ChannelName />
      </div>
      {isRbacEnabled && accessGroupsRender(channelId, onUpdateContainer)}
      <>
        <div className="display_flex mb_2-5">
          <div className="flexBasis_50 mr_3">
            <Members />
          </div>
          <div className="flexBasis_50" />
        </div>
        <div className="mb_2-5">
          <Email />
        </div>
      </>
      <div className="display_flex mb_3">
        <div className="flexBasis_50 mr_3">
          <TimeZone />
        </div>
        <div className="flexBasis_50" />
      </div>

      {isChannelUngroupingEnabled && (
        <div className="mb_2-5">
          <UngroupAlertChannel channelType={channelType} isChannelUngrouped={isChannelUngrouped} />
        </div>
      )}

      <div className="mb_4">
        <Test channelType={channelType} />
      </div>
    </>
  );
};

const WebhookChannelSettings = ({
  channelType,
  isCreate,
  channelId,
  onUpdateContainer,
  isRbacEnabled,
  isChannelUngrouped,
}: {
  channelType: string,
  isCreate: Boolean,
  channelId: string,
  onUpdateContainer: Function,
  isRbacEnabled: Boolean,
  isChannelUngrouped: Boolean,
}) => {
  const isChannelUngroupingEnabled = useSelector(getChannelUngroupingEnabled);
  return (
    <>
      <div className="overflowY_auto">
        <div className="mb_2-5">
          <ChannelName />
        </div>
        <div className="mb_2-5">
          <Url channelType={channelType} />
        </div>
        <div className="display_flex mb_2-5">
          <div className="flexBasis_50 mr_3">
            <TimeZone />
          </div>
          <div className="flexBasis_50" />
        </div>
        {isRbacEnabled && accessGroupsRender(channelId, onUpdateContainer)}
        {isChannelUngroupingEnabled && (
          <div className="mb_2-5">
            <UngroupAlertChannel channelType={channelType} isChannelUngrouped={isChannelUngrouped} />
          </div>
        )}
        <div className="mb_3">
          <Authentication isCreate={isCreate} />
        </div>
        <div className="mb_4">
          <Test channelType={channelType} />
        </div>
      </div>
    </>
  );
};

const JIRAChannelSettings = ({
  channelType,
  initialConnectionStatus,
  channelId,
  onUpdateContainer,
  isRbacEnabled,
  isChannelUngrouped,
}: {
  channelType: string,
  initialConnectionStatus: boolean,
  channelId: string,
  onUpdateContainer: Function,
  isRbacEnabled: Boolean,
  isChannelUngrouped: Boolean,
}) => {
  const isChannelUngroupingEnabled = useSelector(getChannelUngroupingEnabled);

  return (
    <>
      <div className="mb_2-5">
        <ChannelName />
      </div>
      <div className="mb_2-5">
        <Url channelType={channelType} isCreate={!channelId} />
      </div>
      <div className="display_flex mb_2-5">
        <div className="mr_3">
          <JiraUser isCreate={!channelId} />
        </div>
        <AccessToken isCreate={!channelId} />
      </div>
      <div className="display_flex  mb_2-5">
        <div className="flexBasis_50 mr_3">
          <TimeZone />
        </div>
        <div className="flexBasis_50" />
      </div>
      <div className={`${initialConnectionStatus ? 'mb_2-5' : 'mb_7'}`}>
        <Headers />
      </div>

      {initialConnectionStatus && (
        <>
          <div className="display_flex mb_7">
            <div className="flexBasis_50 mr_3">
              <JiraProjectId />
            </div>
            <div className="flexBasis_50">
              <JiraIssueType />
            </div>
          </div>

          <div className="mb_4">
            <Test channelType={channelType} />
          </div>
        </>
      )}

      {isRbacEnabled && accessGroupsRender(channelId, onUpdateContainer)}
      {isChannelUngroupingEnabled && (
        <div className="mb_2-5">
          <UngroupAlertChannel channelType={channelType} isChannelUngrouped={isChannelUngrouped} />
        </div>
      )}
    </>
  );
};

const SnowChannelSettings = ({
  channelType,
  isCreate,
  channelId,
  onUpdateContainer,
  isRbacEnabled,
  isChannelUngrouped,
}: {
  channelType: string,
  isCreate: Boolean,
  channelId: string,
  onUpdateContainer: Function,
  isRbacEnabled: Boolean,
  isChannelUngrouped: Boolean,
}) => {
  const isChannelUngroupingEnabled = useSelector(getChannelUngroupingEnabled);
  return (
    <>
      <div className="mb_2-5">
        <ChannelName />
      </div>
      <div className="mb_2-5">
        <Url channelType={channelType} />
      </div>
      <div className="mb_2-5">
        <div>ServiceNow User ID</div>
        <User />
      </div>
      <div className="mb_2-5">
        <div>Password</div>
        <Password isCreate={isCreate} />
      </div>
      <div className="display_flex  mb_2-5">
        <div className="flexBasis_50">
          <SnowTable />
        </div>
        <div className="flexBasis_50" />
      </div>
      <div className="display_flex  mb_2-5">
        <div className="flexBasis_50">
          <TimeZone />
        </div>
        <div className="flexBasis_50" />
      </div>
      {isRbacEnabled && accessGroupsRender(channelId, onUpdateContainer)}
      {isChannelUngroupingEnabled && (
        <div className="mb_2-5">
          <UngroupAlertChannel channelType={channelType} isChannelUngrouped={isChannelUngrouped} />
        </div>
      )}
      <div className="mb_4">
        <Test channelType={channelType} />
      </div>
    </>
  );
};

const OpsgenieChannelSettings = ({
  channelType,
  channelId,
  onUpdateContainer,
  isRbacEnabled,
}: {
  channelType: string,
  channelId: string,
  onUpdateContainer: Function,
  isRbacEnabled: Boolean,
}) => {
  return (
    <>
      <div className="mb_2-5">
        <ChannelName />
      </div>
      <div className="mb_2-5">
        <UrlDropdown />
      </div>
      <div className="mb_2-5">
        <IntegrationApiKey isCreate={!channelId} />
      </div>
      <div className="display_flex mb_7">
        <div className="flexBasis_50 mr_3">
          <TimeZone />
        </div>
        <div className="flexBasis_50" />
      </div>
      {isRbacEnabled && accessGroupsRender(channelId, onUpdateContainer)}

      <div className="mb_4">
        <Test channelType={channelType} />
      </div>
    </>
  );
};

const SalesforceChannelSettings = ({
  channelId,
  channelType,
  isChannelUngrouped,
  isCreate,
  isRbacEnabled,
  onUpdateContainer,
}: {
  channelId: string,
  channelType: string,
  isChannelUngrouped: Boolean,
  isCreate: Boolean,
  isRbacEnabled: Boolean,
  onUpdateContainer: Function,
}) => {
  const isChannelUngroupingEnabled = useSelector(getChannelUngroupingEnabled);
  return (
    <>
      <div className="mb_2-5">
        <ChannelName />
      </div>
      {isCreate ? (
        <div className="mb_2-5">
          <SalesforceLoginSandbox />
        </div>
      ) : (
        <div className="mb_7">
          {isChannelUngroupingEnabled && (
            <div>
              <div className="mb_2-5">
                <SelectChannelDimOrTag />
              </div>
              <div className="mb_2-5">
                <UngroupAlertChannel channelType={channelType} isChannelUngrouped={isChannelUngrouped} />
              </div>
            </div>
          )}
          {isRbacEnabled && accessGroupsRender(channelId, onUpdateContainer)}
          <div className="display_flex  mb_2-5">
            <div className="flexBasis_50">
              <TimeZone />
            </div>
            <div className="flexBasis_50" />
          </div>
          <Test channelType={channelType} />
        </div>
      )}
    </>
  );
};

export default {
  tamtam: StandartChannelSettings,
  telegram: StandartChannelSettings,
  sns: StandartChannelSettings,
  msteams: StandartChannelSettings,
  msteamsworkflow: StandartChannelSettings,
  slack: StandartChannelSettings,
  mattermost: StandartChannelSettings,
  servicenow: SnowChannelSettings,
  slackapp: StandartChannelSettings,
  pagerduty: StandartChannelSettings,
  email: EmailChannelSettings,
  webhook: WebhookChannelSettings,
  jira: JIRAChannelSettings,
  opsgenie: OpsgenieChannelSettings,
  salesforce: SalesforceChannelSettings,
};
