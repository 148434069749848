import React from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';
import {isValidUrl} from 'common/utils/validation';

const validate = (value) => {
  if (!value) {
    return 'Url is mandatory';
  }
  if (!isValidUrl(value)) {
    return 'A valid url is required';
  }
  return undefined;
};

const placeHoldersMap = {
  tamtam: 'https://tamtam.chat',
  msteams: 'https://outlook.office.com/webhook',
  msteamsworkflow: 'https://outlook.office.com/webhook',
  slack: 'https://hooks.slack.com/services',
  mattermost: 'https://api.mattermost.com/',
  servicenow: 'https://app.service-now.com',
  webhook: 'https://webhook.site',
  jira: 'https://app.atlassian.net',
  telegram: 'https://web.telegram.org/',
};

const Url = ({channelType, isCreate}: {channelType: string, isCreate: boolean}) => {
  const {
    input: {value, onChange, onBlur},
    meta,
  } = useField('url', {validate});

  return (
    <div>
      <div>URL</div>
      <Input
        automationId="url"
        isDisabled={!isCreate && channelType === 'jira'}
        placeHolder={placeHoldersMap[channelType]}
        isInvalid={meta.touched && meta.error}
        invalidMessage={meta.error}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default Url;
