import {
  SourceAwsSns,
  SourceChannelEmails,
  SourceJira,
  SourceOpsgenie,
  SourcePagerDuty,
  SourceSlack,
  SourceMattermost,
  SourceSalesforce,
  SourceServicenow,
  SourceTamtam,
  SourceTeams,
  SourceWebhook,
  SourceTelegram,
} from 'channels/icons/svgs';

export const CHANNEL_ACTION_TYPES = {
  CREATE_INITIAL_CONNECTION: 'createInitialConnection',
  SALESFORCE_LOGIN: 'salesforceLogin',
  SUBMIT: 'submit',
  TEST: 'test',
};

export const CHANNEL_TYPES = {
  EMAIL: 'email',
  JIRA: 'jira',
  MATTERMOST: 'mattermost',
  MSTEAMS: 'msteams',
  MSTEAMSWORKFLOW: 'msteamsworkflow',
  OPSGENIE: 'opsgenie',
  PAGERDUTY: 'pagerduty',
  SALESFORCE: 'salesforce',
  SERVICENOW: 'servicenow',
  SLACKAPP: 'slackapp',
  SLACK: 'slack',
  SNS: 'sns',
  TAMTAM: 'tamtam',
  TELEGRAM: 'telegram',
  WEBHOOK: 'webhook',
};

export default [
  {
    id: CHANNEL_TYPES.EMAIL,
    value: 'email',
    label: 'Email',
    icon: SourceChannelEmails,
  },
  {
    id: CHANNEL_TYPES.JIRA,
    value: 'jira',
    label: 'JIRA',
    icon: SourceJira,
  },
  {
    id: CHANNEL_TYPES.MATTERMOST,
    value: 'mattermost',
    label: 'Mattermost',
    icon: SourceMattermost,
  },
  {
    id: CHANNEL_TYPES.MSTEAMS,
    value: 'msteams',
    label: 'MS Teams',
    icon: SourceTeams,
  },
  {
    id: CHANNEL_TYPES.MSTEAMSWORKFLOW,
    value: 'msteamsworkflow',
    label: 'MS Teams (Workflow)',
    icon: SourceTeams,
  },
  {
    id: CHANNEL_TYPES.OPSGENIE,
    value: 'opsgenie',
    label: 'Opsgenie',
    icon: SourceOpsgenie,
  },
  {
    id: CHANNEL_TYPES.PAGERDUTY,
    value: 'pagerduty',
    label: 'PagerDuty',
    icon: SourcePagerDuty,
  },
  {
    id: CHANNEL_TYPES.SALESFORCE,
    value: 'salesforce',
    label: 'Salesforce',
    icon: SourceSalesforce,
  },
  {
    id: CHANNEL_TYPES.SERVICENOW,
    value: 'servicenow',
    label: 'Service Now (Incident)',
    icon: SourceServicenow,
  },
  {
    id: CHANNEL_TYPES.SLACK,
    value: 'slack',
    label: 'Slack (Legacy)',
    icon: SourceSlack,
  },
  {
    id: CHANNEL_TYPES.SLACKAPP,
    value: 'slackapp',
    label: 'Slack (App)',
    icon: SourceSlack,
  },
  {
    id: CHANNEL_TYPES.SNS,
    value: 'sns',
    label: 'SNS',
    icon: SourceAwsSns,
  },
  {
    id: CHANNEL_TYPES.TAMTAM,
    value: 'tamtam',
    label: 'Tam Tam',
    icon: SourceTamtam,
  },
  {
    id: CHANNEL_TYPES.TELEGRAM,
    value: 'telegram',
    label: 'Telegram',
    icon: SourceTelegram,
  },
  {
    id: CHANNEL_TYPES.WEBHOOK,
    value: 'webhook',
    label: 'Webhook',
    icon: SourceWebhook,
  },
];

export const AUTH_METHODS = {
  NO_AUTH: {label: 'No Auth', value: 'NoAuth'},
  BASIC: {label: 'Basic', value: 'Basic'},
  OKTA: {label: 'Okta', value: 'Okta'},
};
