import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'common/componentsV2/Tooltip';
import Button, {COLORS, HEIGHTS, WIDTHS} from 'common/componentsV2/Button';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {
  OPTIONS_ACTIONS_TOOLBAR,
  OPTIONS_ACTIONS_TOOLBAR_VALUE,
  TYPE_COMPOSITES,
} from 'composites/services/compositesService';
import {useHistory} from 'react-router-dom';
import fetchConfigurationAlertByIdProvider from 'alerts.management/api/fetchConfigurationAlertByIdProvider';
import './CompositeItemActionToolbar.module.scss';

const CompositeItemActionToolbar = ({item, unSelectItem}) => {
  const history = useHistory();
  const optionsActions = OPTIONS_ACTIONS_TOOLBAR.filter((o) => o.type.includes(item.composite.mtype.toLowerCase()));

  const onClickViewBtn = useCallback(async () => {
    if (item.composite.mtype === TYPE_COMPOSITES.COMPOSITES) {
      if (item.composite.expressionTree) {
        window.sessionStorage.setItem('metricExplorerTree', JSON.stringify([item.composite.expressionTree]));
      }
      window.open('/#!/r/metrics-explorer');
    } else {
      const alertConfiguration = await fetchConfigurationAlertByIdProvider().fetchQuery(item.composite.originId);
      window.open(`/#!/r/alert-manager?searchQuery=${alertConfiguration.data.title}`);
    }
  }, [item]);

  const onSelectAction = useCallback(
    ({value}) => {
      switch (value) {
        case OPTIONS_ACTIONS_TOOLBAR_VALUE.DUPLICATE:
          return history.push(`${history.location.pathname}/duplicate`);
        case OPTIONS_ACTIONS_TOOLBAR_VALUE.EDIT: {
          window.open(
            `/#!/r/alert-manager/edit/${item.composite.originId}/settings?backUrl=${history.location.pathname}`,
          );
          return null;
        }
        case OPTIONS_ACTIONS_TOOLBAR_VALUE.DELETE:
          return history.push(`${history.location.pathname}/delete/${item.composite.id}${history.location.search}`);
        case OPTIONS_ACTIONS_TOOLBAR_VALUE.CREATE_ALERT:
          return history.push('/alert-manager/new');
        case OPTIONS_ACTIONS_TOOLBAR_VALUE.COMPOSITE_DELAY:
          if (unSelectItem) unSelectItem();
          return history.push(`${history.location.pathname}/delay/${item.composite.id}${history.location.search}`);
        case OPTIONS_ACTIONS_TOOLBAR_VALUE.USAGE_HISTORY:
          return history.push('/metrics-explorer');
        case OPTIONS_ACTIONS_TOOLBAR_VALUE.VIEW_ACTIVITY_LOG:
          return history.push('/admin/activity-log');
        default:
          return null;
      }
    },
    [item],
  );
  return (
    <div styleName="action-toolbox">
      <div styleName="btn">
        <Tooltip
          content={
            item.composite.mtype === TYPE_COMPOSITES.ALERT ? 'View in Alert Management' : 'View in Metric Explorer'
          }
          delay={300}
        >
          <div>
            <Button
              colorSchema={COLORS.GRAY_300}
              width={WIDTHS.NARROW}
              onClick={onClickViewBtn}
              icon="icn-general16-show"
              height={HEIGHTS.TIGHT30}
              automationId="compositeViewInMetricExplorerBtn"
            />
          </div>
        </Tooltip>
      </div>
      <div styleName="btn">
        <FormDdlSelect
          options={optionsActions}
          // selected={}
          button={
            <Button
              colorSchema={COLORS.GRAY_300}
              width={WIDTHS.NARROW}
              icon="icn-general16-3dot"
              height={HEIGHTS.TIGHT30}
              automationId="compositeViewInMetricExplorerBtn"
            />
          }
          optionComponent={<OptionComponentSimple />}
          onChange={onSelectAction}
          position="right"
          width={208}
          automationId="actionsCompositeItem"
        />
      </div>
    </div>
  );
};

CompositeItemActionToolbar.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  unSelectItem: PropTypes.func,
};

CompositeItemActionToolbar.defaultProps = {
  unSelectItem: null,
};

export default React.memo(CompositeItemActionToolbar);
