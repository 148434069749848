import React from 'react';
import {isEmpty} from 'lodash';
import {useField, useForm} from 'react-final-form';
import {makeStyles} from '@material-ui/core';
import Spinner from 'common/componentsV2/Spinner';
import {getTestChannel} from 'alerts.channels/store/selectors';
import {palette} from 'app/styles/theme';
import {useSelector} from 'react-redux';
import getValidationErrorMsg from 'channels/services/channelsService';
import {CHANNEL_ACTION_TYPES, CHANNEL_TYPES} from 'channels/constants/channelTypes';

const useStyles = makeStyles(() => ({
  button: {
    background: palette.blue[100],
    border: `2px solid ${palette.blue[300]}`,
    borderRadius: '6px',
    color: palette.blue[500],
    cursor: 'pointer',
    padding: '10px 12px',
    display: 'inline-block',
    lineHeight: '14px',
    fontWeight: 500,
    pointerEvents: ({errors}) => (isEmpty(errors) ? 'auto' : 'none'),
    opacity: ({errors}) => (isEmpty(errors) ? 1 : 0.5),
  },
  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '16px',
    height: '16px',
    marginRight: '4px',
    '& > div': {
      marginLeft: '-4px',
    },
  },
}));

const Test = ({channelType}: {channelType: string}) => {
  const testResult = useSelector(getTestChannel);
  const {submit, getState} = useForm();
  const {submitSucceeded, submitFailed, submitting, errors} = getState();
  const classes = useStyles({errors});

  const {
    input: {value: action, onChange: setAction},
  } = useField('action');

  const onClick = () => {
    setAction(CHANNEL_ACTION_TYPES.TEST);
    return submit();
  };

  const getTestSuccessMessage = () => {
    if (channelType === CHANNEL_TYPES.EMAIL) {
      return 'Email Sent';
    }
    if (channelType === CHANNEL_TYPES.MSTEAMSWORKFLOW) {
      return 'Check the channel to view the test message';
    }
    return 'Test Passed';
  };

  const doesMessageExist = action === CHANNEL_ACTION_TYPES.TEST && (submitting || submitSucceeded || submitFailed);
  const testFailedMessage = submitFailed && getValidationErrorMsg(testResult?.data?.validationErrors?.errors[0]?.error);

  return (
    <>
      <div className={classes.button} automation-id="test" onClick={onClick}>
        {channelType === CHANNEL_TYPES.EMAIL ? 'Send Test Email' : 'Test Connection'}
      </div>
      {doesMessageExist && (
        <div className="fontSize_12 fontWeight_500 mt_0-5 display_flex alignItems_center">
          {submitting && (
            <>
              <div className={classes.spinnerWrapper}>
                <Spinner color={palette.gray[500]} size={24} />
              </div>
              {channelType === CHANNEL_TYPES.EMAIL ? 'Sending Email' : 'Testing Connection'}
            </>
          )}
          {submitSucceeded && !submitFailed && (
            <>
              <i className="icon icn-general16-checkmark fontSize_16 color_blue-500 mr_0-5" />
              Success! {getTestSuccessMessage()}
            </>
          )}
          {submitFailed && (
            <>
              <i className="icon icn-general16-warning fontSize_16 color_red-500 mr_0-5" />
              {testFailedMessage}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Test;
