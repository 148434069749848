import {AUTH_METHODS, CHANNEL_TYPES} from 'channels/constants/channelTypes';

const makeChannelPayload = (values, channelType, isCreate) => {
  if (channelType === CHANNEL_TYPES.EMAIL) {
    return {
      channelData: {
        emailAddresses: [...values.email.map((item) => item.value), ...values.members.map((item) => item.value)],
      },
    };
  }

  if (channelType === CHANNEL_TYPES.WEBHOOK) {
    let authenticationData = {};
    let authChannelData = {};

    if (values?.authentication === AUTH_METHODS.BASIC.value) {
      if (values?.password) {
        authenticationData = {token: values.password};
        authChannelData = {authenticate: AUTH_METHODS.BASIC.value, user: values.user};
      } else if (!isCreate) {
        authenticationData = null;
        authChannelData = {authenticate: AUTH_METHODS.BASIC.value, user: values.user};
      }
    } else if (values?.authentication === AUTH_METHODS.OKTA.value) {
      authChannelData = {
        authenticate: AUTH_METHODS.OKTA.value,
        clientId: values.oktaClientId,
        issuerUrl: values.oktaIssuerUrl,
        scope: values.oktaScope,
      };
    }

    return {
      authData: authenticationData,
      channelData: {
        url: values.url,
        ...authChannelData,
      },
    };
  }

  if (channelType === CHANNEL_TYPES.PAGERDUTY) {
    return {
      channelData: {
        serviceKey: values.serviceKey,
        serviceName: values.channelName,
      },
    };
  }

  if (channelType === CHANNEL_TYPES.JIRA) {
    return {
      ...(isCreate
        ? {
            authData: {
              token: values.accessToken,
            },
          }
        : {}),
      channelData: {
        projectId: values.jiraProjectId,
        issueType: values.jiraIssueType,
        userEmail: values.jiraUser,
        jiraBaseURL: values.url,
      },
    };
  }

  if (channelType === CHANNEL_TYPES.OPSGENIE) {
    return {
      ...(isCreate
        ? {
            authData: {
              token: values.integrationApiKey,
            },
          }
        : {}),
      channelData: {
        baseUrl: values.urlDropdown === 'new' ? values.url : values.urlDropdown,
      },
    };
  }

  if (channelType === CHANNEL_TYPES.SNS) {
    return {
      channelData: {topic: values.topic},
    };
  }

  if (
    [CHANNEL_TYPES.TAMTAM, CHANNEL_TYPES.MSTEAMS, CHANNEL_TYPES.MSTEAMSWORKFLOW, CHANNEL_TYPES.TELEGRAM].includes(
      channelType,
    )
  ) {
    return {
      channelData: {url: values.url},
    };
  }

  if (channelType === CHANNEL_TYPES.SLACKAPP) {
    return {
      channelData: {
        url: values.url,
      },
    };
  }

  if (channelType === CHANNEL_TYPES.SLACK) {
    return {
      channelData: {
        url: values.url,
        channel: `${values.channelDataName}`,
      },
    };
  }

  if (channelType === CHANNEL_TYPES.MATTERMOST) {
    return {
      channelData: {
        url: values.url,
        channel: `${values.channelDataName}`,
      },
    };
  }

  if (channelType === CHANNEL_TYPES.SERVICENOW) {
    return {
      ...(values.password?.length > 0
        ? {
            authData: {
              token: values.password,
            },
          }
        : {}),
      channelData: {
        table: values.snowTable?.value || values.snowTable,
        baseUrl: values.url,
        user: values.user,
      },
    };
  }

  if (channelType === CHANNEL_TYPES.SALESFORCE) {
    return {
      ...(isCreate
        ? {
            authData: {
              code: values.salesforceLoginCode,
              staging: values.isSalesforceSandboxChecked,
            },
          }
        : {}),
      channelData: {
        accountDimension: values.accountDimension,
        userName: values.salesforceUserName,
      },
    };
  }

  return {};
};

export default makeChannelPayload;
