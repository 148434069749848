import {rangeTypes} from 'common/utils/dateRangeService';
import {get, uniqWith} from 'lodash';
import {bcTypes} from './bcTypes';
import {getEditorValidationMessage as getGaEditorValidationMessage} from './gaService';
import {
  allowedPollingIntervalsByFileDatePattern,
  getEditorValidationMessage as getGenericStorageEditorValidationMessage,
} from './genericStorageService';
import {getEditorValidationMessage as getGsEditorValidationMessage} from './gsService';
import getAdobeEditorValidationMessage from './adobeService';
import {getEditorValidationMessage as getBigQueryEditorValidationMessage} from './bqService';
import {getEditorValidationMessage as getSqlEditorValidationMessage} from './sqlService';
import getMParticleEditorValidationMessage from './mParticleService';
import {getEditorValidationMessage as fuGetEditorValidationMessage} from './fuService';
import {getEditorValidationMessage as kinesisGetEditorValidationMessage} from './kinesisService';
import {getEditorValidationMessage as getSalesforceEditorValidationMessage} from './salesforceService';
import {getEditorValidationMessage as getGoogleAdsEditorValidationMessage} from './gadsService';
import {getEditorValidationMessage as getAwsCurEditorValidationMessage} from './awsCurService';
import {getEditorValidationMessage as getFacebookAdsEditorValidationMessage} from './fbService';
import {getEditorValidationMessage as getGoogleAuctionsEditorValidationMessage} from './googleAuctionsService';
import {getEditorValidationMessage as getCoralogixEditorValidationMessage} from './coralogixService';
import {getEditorValidationMessage as getDatadogEditorValidationMessage} from './datadogService';
import {getEditorValidationMessage as getSumologicEditorValidationMessage} from './sumologicService';
import {getEditorValidationMessage as getGA4EditorValidationMessage} from './ga4Service';

export const QUERY_KEYS = {
  DATA_SOURCE: 'dataSourceList',
  DATA_STREAM: 'dataStreamList',
};

export const excludeTemplatesNames = ['Campaign Performance', 'Landing Page Performance'];

export const pollingIntervals = {
  m1: {
    key: 'm1',
    label: '1 Minute',
    labelShort: '1 Minute',
    timeScale: 'short',
  },
  m5: {
    key: 'm5',
    label: '5 Minutes',
    labelShort: '5 Minutes',
    timeScale: 'medium',
  },
  m10: {
    key: 'm10',
    label: '10 Minutes',
    labelShort: '10 Minutes',
    timeScale: 'medium',
  },
  m15: {
    key: 'm15',
    label: '15 Minutes',
    labelShort: '15 Minutes',
    timeScale: 'medium',
  },
  m30: {
    key: 'm30',
    label: '30 Minutes',
    labelShort: '30 Minutes',
    timeScale: 'medium',
  },
  hourly: {
    key: 'hourly',
    label: 'Hourly',
    labelShort: 'Hour',
    timeScale: 'long',
  },
  h2: {
    key: 'h2',
    label: '2 Hours',
    labelShort: '2 Hours',
    timeScale: 'long',
  },
  h3: {
    key: 'h3',
    label: '3 Hours',
    labelShort: '3 Hours',
    timeScale: 'long',
  },
  h4: {
    key: 'h4',
    label: '4 Hours',
    labelShort: '4 Hours',
    timeScale: 'long',
  },
  h6: {
    key: 'h6',
    label: '6 Hours',
    labelShort: '6 Hours',
    timeScale: 'long',
  },
  h8: {
    key: 'h8',
    label: '8 Hours',
    labelShort: '8 Hours',
    timeScale: 'long',
  },
  h12: {
    key: 'h12',
    label: '12 Hours',
    labelShort: '12 Hours',
    timeScale: 'long',
  },
  daily: {
    key: 'daily',
    label: 'Daily',
    labelShort: 'Day',
    timeScale: 'longlong',
  },
};

const fiveWeeks = 60 * 60 * 24 * 7 * 5;
export const resolutionsShort = {
  m15: {
    key: 'm15',
    label: '15 Minutes',
    defaultPolling: pollingIntervals.m15.key,
    maxRange: 60 * 60 * 24 * 30,
  },
  m30: {
    key: 'm30',
    label: '30 Minutes',
    defaultPolling: pollingIntervals.m30.key,
    maxRange: 60 * 60 * 24 * 30,
  },
  hours: {
    key: 'hours',
    label: '1 Hour',
    defaultPolling: pollingIntervals.hourly.key,
    maxRange: fiveWeeks,
  },
  days: {
    key: 'days',
    label: '1 Day',
    defaultPolling: pollingIntervals.daily.key,
    maxRange: 60 * 60 * 24 * 500,
  },
};

export const resolutions = {
  // minutes: {key: 'minutes', label: '1 Minute'},
  hours: {
    key: 'hours',
    label: '1 Hour',
    defaultPolling: pollingIntervals.hourly.key,
    maxRange: fiveWeeks,
  },
  days: {
    key: 'days',
    label: '1 Day',
    defaultPolling: pollingIntervals.daily.key,
    maxRange: 60 * 60 * 24 * 500,
  },
};

export const getAllowedRangeTypesByPollingInterval = (pollingInterval, isBcUnlimitedFileUploadTimeRange) => {
  if (isBcUnlimitedFileUploadTimeRange) {
    return [
      rangeTypes.d1.key,
      rangeTypes.d3.key,
      rangeTypes.w1.key,
      rangeTypes.m1.key,
      rangeTypes.m3.key,
      rangeTypes.m6.key,
      rangeTypes.y1.key,
      rangeTypes.y2.key,
      rangeTypes.y5.key,
      rangeTypes.y10.key,
      rangeTypes.y15.key,
      rangeTypes.y20.key,
    ];
  }
  switch (pollingInterval) {
    case pollingIntervals.m1.key:
      return [rangeTypes.h1.key, rangeTypes.h4.key, rangeTypes.d1.key, rangeTypes.d3.key, rangeTypes.w1.key];
    case pollingIntervals.m5.key:
    case pollingIntervals.m10.key:
    case pollingIntervals.m15.key:
    case pollingIntervals.m30.key:
      return [
        rangeTypes.h1.key,
        rangeTypes.h4.key,
        rangeTypes.d1.key,
        rangeTypes.d3.key,
        rangeTypes.w1.key,
        rangeTypes.m1.key,
        rangeTypes.m3.key,
      ];
    case pollingIntervals.hourly.key:
    case pollingIntervals.h2.key:
    case pollingIntervals.h3.key:
    case pollingIntervals.h4.key:
    case pollingIntervals.h6.key:
    case pollingIntervals.h8.key:
    case pollingIntervals.h12.key:
      return [
        rangeTypes.d1.key,
        rangeTypes.d3.key,
        rangeTypes.w1.key,
        rangeTypes.m1.key,
        rangeTypes.m3.key,
        rangeTypes.m6.key,
        rangeTypes.y1.key,
      ];
    case pollingIntervals.daily.key:
      return [
        rangeTypes.d1.key,
        rangeTypes.d3.key,
        rangeTypes.w1.key,
        rangeTypes.m1.key,
        rangeTypes.m3.key,
        rangeTypes.m6.key,
        rangeTypes.y1.key,
        rangeTypes.y2.key,
        rangeTypes.y5.key,
        rangeTypes.y10.key,
        rangeTypes.y15.key,
        rangeTypes.y20.key,
      ];
    default:
      return [];
  }
};

export const getResolutionByPollingInterval = (intervalKey) => {
  switch (intervalKey) {
    case pollingIntervals.h12.key:
    case pollingIntervals.h8.key:
    case pollingIntervals.h6.key:
    case pollingIntervals.h4.key:
    case pollingIntervals.h3.key:
    case pollingIntervals.h2.key:
    case pollingIntervals.hourly.key:
    case pollingIntervals.m30.key:
    case pollingIntervals.m15.key:
    case pollingIntervals.m10.key:
    case pollingIntervals.m5.key:
    case pollingIntervals.m1.key:
      return resolutions.hours.key;
    default:
      return resolutions.days.key;
  }
};

export const getResolutionByRange = (dateRange, curResolution) => {
  if (dateRange.endDate - dateRange.startDate > fiveWeeks) {
    return resolutions.days.key;
  }
  return curResolution;
};

export const streamStartActions = {
  start: 'start',
  pause: 'pause',
  resume: 'resume',
};

export const getEditorValidationMessage = (stream) => {
  if (!stream) {
    return null;
  }
  if (!stream.name.length) {
    return 'Data stream name is mandatory';
  }

  switch (stream.type) {
    case bcTypes.google_analytics.type:
      return getGaEditorValidationMessage(stream);
    case bcTypes.local_file.type:
      return fuGetEditorValidationMessage(stream);
    case bcTypes.kinesis.type:
    case bcTypes.eventhubs.type:
      return kinesisGetEditorValidationMessage(stream);
    case bcTypes.s3.type:
      return getGenericStorageEditorValidationMessage(stream);
    case bcTypes.google_storage.type:
      return getGsEditorValidationMessage(stream);
    case bcTypes.adobe.type:
      return getAdobeEditorValidationMessage(stream);
    case bcTypes.bigquery.type:
      return getBigQueryEditorValidationMessage(stream);
    case bcTypes.mysql.type:
    case bcTypes.psql.type:
    case bcTypes.mssql.type:
    case bcTypes.mariadb.type:
    case bcTypes.redshift.type:
    case bcTypes.snowflake.type:
    case bcTypes.oracle.type:
    case bcTypes.databricks.type:
    case bcTypes.teradata.type:
    case bcTypes.athena_sql.type:
    case bcTypes.pinot.type:
    case bcTypes.timestream.type:
      return getSqlEditorValidationMessage(stream);
    case bcTypes.google_auctions.type:
      return getGoogleAuctionsEditorValidationMessage(stream);
    case bcTypes.mparticle.type:
      return getMParticleEditorValidationMessage(stream);
    case bcTypes.salesforce.type:
      return getSalesforceEditorValidationMessage(stream);
    case bcTypes.google_ads.type:
      return getGoogleAdsEditorValidationMessage(stream);
    case bcTypes.facebook_ads.type:
      return getFacebookAdsEditorValidationMessage(stream);
    case bcTypes.aws_cur.type:
      return getAwsCurEditorValidationMessage(stream);
    case bcTypes.coralogix.type:
      return getCoralogixEditorValidationMessage(stream);
    case bcTypes.datadog.type:
      return getDatadogEditorValidationMessage(stream);
    case bcTypes.sumologic.type:
      return getSumologicEditorValidationMessage(stream);
    case bcTypes.google_analytics_ga4.type:
      return getGA4EditorValidationMessage(stream);
    default:
  }
  return null;
};

export const getTableValidationMessage = (stream, validationMeta) => {
  if (!stream) {
    return null;
  }

  const uniqueTitles = uniqWith(
    stream.schema.columns,
    (curItem, otherItem) => curItem.name === otherItem.name && !curItem.hidden && !otherItem.hidden,
  );
  if (uniqueTitles.length !== stream.schema.columns.length) {
    return 'Column titles must be unique';
  }

  const dimRegularColCount = stream.schema.columns.filter(
    (a) => a.type === 'dimension' && !a.hidden && a.name[0] !== '#',
  );
  if (dimRegularColCount.length > validationMeta.propMaxColumnNumber) {
    return `Max number of regular dimensions allowed is ${validationMeta.propMaxColumnNumber}`;
  }

  const dimTagsColCount = stream.schema.columns.filter((a) => a.type === 'dimension' && !a.hidden && a.name[0] === '#');
  if (dimTagsColCount.length > validationMeta.tagMaxColumnNumber) {
    return `Max number of tag dimensions allowed is ${validationMeta.tagMaxColumnNumber}`;
  }

  const hasTitleToLong = stream.schema.columns.filter(
    (a) => !a.hidden && a.name.length > validationMeta.propNameLength,
  );
  if (hasTitleToLong.length) {
    return 'Column title max length is 50 keys';
  }

  const noTargetTypeSetForMetrics = stream.schema.columns.filter(
    (a) => a.type === 'metric' && !a.targetType && !a.hidden,
  );
  if (noTargetTypeSetForMetrics.length) {
    return 'Measures require an aggregation function ';
  }
  return null;
};

export const isAlowedEditRunning = (streamType) =>
  [
    'mysql',
    'psql',
    'mssql',
    'mariadb',
    'redshift',
    'snowflake',
    'oracle',
    'databricks',
    's3',
    'bigquery',
    'google_ads',
    'aws_cur',
    'kinesis',
    'salesforce',
    'facebook_ads',
    'google_analytics',
    'google_analytics_ga4',
    'teradata',
    'google_auctions',
    'coralogix',
    'athena_sql',
    'newrelic',
    'datadog',
    'mxpnl',
    'sumologic',
    'google_search',
    'pinot',
  ].includes(streamType);
// ['s3'].includes(streamType);

export const isStreamEditable = (state) => state === 'new';
export const isStreamFinished = (state) => state === 'finished';
export const isStreamFailed = (status) => status === 'failed';
export const isStreamRunning = (status) => status === 'running' || status === 'RUNNING';
export const isStreamFileUpload = (type) => type === 'local_file';
export const isEditRunning = (id) => id === 'EditRunning';
export const isAgent = (family) => family === 'agents';
export const isApi30schema = (family) => family === 'api30schema';
export const isAgentMetrics30 = (config) => config && (config.protocol === 'anodot30' || config.uses_schema);

export const getStreamQueryId = (stream) => {
  const properties = [
    'dimensions',
    'metrics',
    'pollingInterval',
    'pollingResolution',
    'delayMinutes',
    'historicalDateRange.constRange',
    'reportId',
  ];
  let keyId = 'queryId-';

  properties.forEach((property) => {
    keyId += get(stream, property, '');
  });
  return keyId;
};

export const getStreamStatusText = (status, failureMessage) => {
  switch (status) {
    case 'no_permission':
      return 'Anodot no longer has permission to access the data source';
    case 'invalid_query':
      return 'Stream query is invaid';
    case 'unknown_error':
      return 'an unknown error has occurred';
    case 'failed':
      return failureMessage;
    default:
  }
  return null;
};

export const streamHasMetrics = (state) => {
  switch (state) {
    case 'initializing_p0':
    case 'initializing_p1':
    case 'initializing_p2':
    case 'initializing_p3':
    case 'initializing_p4':
    case 'new':
      return false;
    case 'running':
    case 'finished':
      return true;
    default:
      return false;
  }
};

export const isFileStream = (type) => type === 'google_storage' || type === 's3' || type === 'local_file';

export const getStreamStatsErrorMessage = (code) => {
  switch (code) {
    case '1':
      return 'Parsing failure. check parse settings and chosen timestamp pattern and then retry. ';
    case '2':
      return 'Timestamp could not be analyzed according to timestamp pattern definition. Check pattern and retry. ';
    case '3':
      return 'Timestamp field is empty. Row will be ignored. ';
    case '4':
      return 'Timestamp field is empty. Row will be ignored. ';
    case '5':
      return 'Data record does not match the filename timestamp. Row will be ignored. ';
    case '105':
      return 'Records do not match the query time range and will be ignored. ';
    case '6':
      return 'The file contains rows with future dates or dates older than 2 years. These rows will not be processed. ';
    default:
      return 'Unknown Errors ';
  }
};

export const getPollingIntervalOption = (type, fileNamePattern, isS3ShortIntervalEnabled) => {
  switch (type) {
    case bcTypes.google_analytics.type:
    case bcTypes.bigquery.type:
    case bcTypes.salesforce.type:
    case bcTypes.adobe.type:
      return ['daily', 'hourly'];
    case bcTypes.google_storage.type:
      return allowedPollingIntervalsByFileDatePattern[fileNamePattern];
    case bcTypes.kinesis.type:
    case bcTypes.eventhubs.type:
    case bcTypes.datadog.type:
      return ['hourly', 'm30', 'm15', 'm10', 'm5', 'm1'];
    case bcTypes.s3.type:
      if (isS3ShortIntervalEnabled && fileNamePattern === 'yyyyMMddHHmm') {
        return ['daily', 'h12', 'h8', 'h6', 'h4', 'h3', 'h2', 'hourly', 'm15', 'm5', 'm1'];
      }
      return allowedPollingIntervalsByFileDatePattern[fileNamePattern];
    case bcTypes.mysql.type:
    case bcTypes.psql.type:
    case bcTypes.mssql.type:
    case bcTypes.mariadb.type:
    case bcTypes.redshift.type:
    case bcTypes.snowflake.type:
    case bcTypes.oracle.type:
    case bcTypes.databricks.type:
    case bcTypes.teradata.type:
    case bcTypes.pinot.type:
    case bcTypes.athena_sql.type:
      return ['daily', 'h12', 'h8', 'h6', 'h4', 'h3', 'h2', 'hourly', 'm15', 'm5', 'm1'];
    default:
  }
  return null;
};

export const getNotificationDelayOption = () => {
  return {
    dailyMin: 1,
    dailyDefault: 6,
    midMin: 1,
    midDefault: 3,
    hourDefault: 1,
    minutesMin: 15,
    minutesMax: 60,
    hoursMax: 24,
  };
};

export const getNotificationDelay = (val) => {
  if (!val) {
    return 'None';
  }
  let notificationDelayScale = val.slice(-1) === 'h' ? 'Hour' : 'Minute';
  const notificationDelay = val.slice(0, -1);
  if (notificationDelay === '1') {
    notificationDelayScale += 's';
  }
  return `${notificationDelay} ${notificationDelayScale}`;
};

export const getMissingHours = (val) => {
  if (val) {
    if (parseInt(val, 10) % 24 === 0) {
      return `${parseInt(val, 10) / 24} Days`;
    }
    return `${parseInt(val, 10)} Hours`;
  }
  return '0 Days';
};

export const isStreamItAlertsAndDashboardsFlow = (stream) =>
  ['google_ads', 'aws_cur', 'snowflake', 'google_auctions'].includes(stream.type) && stream.basedOnTemplateId;
