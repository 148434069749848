import React, {useEffect, useMemo, useState} from 'react';
import {success, warning} from 'common/utils/notifications/notificationsService';
import opsgenieOptions from 'channels/constants/opsgenieOptions';
import {getUsersData} from 'admin.users/store/selectors';
import {getChannelUngroupingEnabled, getUserProfile} from 'profile/store/selectors';
import makeChannelPayload from 'channels/utils/makeChannelPayload';
import {
  createInitialConnection,
  fetchChannels,
  postChannel,
  putChannel,
  testChannel,
} from 'alerts.channels/store/actions';
import {setAccesslistForChannel} from 'admin.permissions/store/actions';
import useAsyncAction from 'common/utils/useAsyncAction';
import {
  getActiveChannels,
  getCreateInitialConnection,
  getFetchChannels,
  getPostChannel,
  getPutChannel,
  getTestChannel,
} from 'alerts.channels/store/selectors';
import {getIsRbacEnabled, getSetAccessListForChannels} from 'admin.permissions/store/selectors';
import {get, isEmpty, keyBy} from 'lodash';
import channelTypes, {CHANNEL_ACTION_TYPES, CHANNEL_TYPES} from 'channels/constants/channelTypes';
import infoLinks from 'channels/constants/infoLinks';
import AsyncButton from 'common/componentsV2/AsyncButton';
import {Form} from 'react-final-form';
import {Backdrop, makeStyles, Modal} from '@material-ui/core';
import {useSelector, useStore} from 'react-redux';
import settingsComponentsMap from 'channels/components/channelSettings/settingsComponents';
import getValidationErrorMsg from 'channels/services/channelsService';
import fetchSalesforceChannelClientIdProvider from 'channels/api/fetchSalesforceChannelClientIdProvider';
import {salesforceLoginAsync} from 'channels/services/salesforceService';

const useStyles = makeStyles(({palette}) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    width: 445,
    height: 'fit-content',
    maxHeight: 550,
    backgroundColor: palette.white[500],
    borderRadius: 7,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.3)',
    margin: 'auto',
    padding: '16px 24px',
  },
  settingsComponentWrapper: {
    overflowY: 'auto',
  },
  footer: {
    height: 65,
    paddingTop: 12,
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '36px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  text: {
    fontSize: '14px',
    marginBottom: '40px',
  },
  logo: {
    width: '20px',
    height: '20px',
    marginRight: '6px',
  },
  closeModal: {
    position: 'absolute',
    right: 0,
  },
  initialConnectionValidation: {
    display: 'flex',
    width: '160px',
  },
  moreInfo: {
    color: palette.blue[500],
    '&:hover, &:active': {
      color: palette.blue[500],
    },
  },
}));

const opsgenieSet = new Set(opsgenieOptions.map((item) => item.value));

const channelsByValue = keyBy(channelTypes, 'value');

const getUserName = (users, email) => {
  const user = users.find((item) => item.email === email);
  if (user) {
    return `${user.firstName} ${user.lastName}`;
  }
  return email;
};

const makeErrorHandler = (errorName) => (response) => {
  if (get(response, 'data.validationErrors.errors[0]')) {
    return {
      [errorName]: getValidationErrorMsg(
        response.data.validationErrors.errors[0].error,
        'Please check your channel settings.',
      ),
    };
  }
  if (get(response, 'data.errors[0]')) {
    return {
      [errorName]: getValidationErrorMsg(response.data.errors[0].error, 'Please check your channel settings.'),
    };
  }
  if (get(response, 'data.warnings[0]')) {
    return {
      [errorName]: getValidationErrorMsg(response.data.warnings[0].error, 'Please check your channel settings.'),
    };
  }
  if (response.error) {
    return {
      [errorName]: response.error.uMessage,
    };
  }
  return null;
};

const NewEditChannel = ({match, onClose}: {match: Object, onClose: Function}) => {
  const {channelId, channelType} = match.params;
  const [initialConnectionStatus, setInitialConnectionStatus] = useState(false);
  // This field is created since for salesforce the flow is that after channel is created - update view is displayed, before closing the dialog.
  const [currentChannelId, setCurrentChannelId] = useState(channelId);
  const [accessGroups, setAccessGroups] = useState([]); // RBAC
  const channelsData = useSelector(getActiveChannels);
  const me = useSelector(getUserProfile);
  const classes = useStyles();
  const store = useStore();
  const users = useSelector(getUsersData);
  const isRbacEnabled = useSelector(getIsRbacEnabled);
  const isChannelUngroupingEnabled = useSelector(getChannelUngroupingEnabled);

  const salesforceClientId = fetchSalesforceChannelClientIdProvider()?.useQuery()?.data;

  const postChannelAsync = useAsyncAction(postChannel, getPostChannel, makeErrorHandler('submitError'));
  const putChannelAsync = useAsyncAction(putChannel, getPutChannel, makeErrorHandler('submitError'));
  const testChannelAsync = useAsyncAction(testChannel, getTestChannel, makeErrorHandler('testError'));
  const createInitialConnectionAsync = useAsyncAction(
    createInitialConnection,
    getCreateInitialConnection,
    makeErrorHandler('connectionError'),
  );
  const fetchChannelsAsync = useAsyncAction(fetchChannels, getFetchChannels, makeErrorHandler('fetchChannelsError'));
  const setAccesslist = useAsyncAction(
    setAccesslistForChannel,
    getSetAccessListForChannels,
    makeErrorHandler('setAccesslistError'),
  );

  const modalType = currentChannelId
    ? get(channelsData.find((item) => item.id === currentChannelId), 'channelMeta.id')
    : channelType;

  useEffect(() => {
    if (modalType === CHANNEL_TYPES.JIRA && currentChannelId) {
      createInitialConnectionAsync({
        currentChannelId,
      }).then((errors) => {
        if (!errors) {
          setInitialConnectionStatus(true);
        }
        return errors;
      });
    }
  }, [channelsData]);

  const channel = currentChannelId ? channelsData.find((item) => item.id === currentChannelId) : null;
  const tags = channel ? JSON.parse(channel.tags) : {};

  // eslint-disable-next-line consistent-return
  const saveChannel = async ({action, ...values}) => {
    if (action === CHANNEL_ACTION_TYPES.CREATE_INITIAL_CONNECTION) {
      return createInitialConnectionAsync({
        accessToken: values.accessToken,
        jiraBaseURL: values.url,
        userEmail: values.jiraUser,
        headers: JSON.stringify(values.headers || {}),
      }).then((errors) => {
        if (!errors) {
          setInitialConnectionStatus(true);
        }
        return errors;
      });
    }

    let salesforceCode;
    if (action === CHANNEL_ACTION_TYPES.SALESFORCE_LOGIN) {
      try {
        const result = await salesforceLoginAsync(salesforceClientId, me, values.isSalesforceSandboxChecked);
        if (result?.code) {
          salesforceCode = result.code;
        } else {
          warning({
            title: `Authentication failed for ${channelsByValue[modalType].label} Channel`,
            description: `${result.error || ''}`,
          });
          return result.error;
        }
      } catch (errors) {
        return errors;
      }
    }
    let tmpValues = values;
    if (salesforceCode) {
      tmpValues = {...values, salesforceLoginCode: salesforceCode};
    }

    const payload = {
      channelType: modalType,
      body: {
        ...makeChannelPayload(tmpValues, modalType, !currentChannelId),
        headers: values.headers || {},
        name: values.channelName,
        timezone: values.timeZone,
        tags: JSON.stringify({
          type: modalType,
          owner: !currentChannelId ? me.email : tags.owner,
          ...(modalType === CHANNEL_TYPES.EMAIL && values.members.length > 0
            ? {users: values.members.map((item) => item.value)}
            : {}),
        }),
        state: 'ACTIVE',
        ...(isChannelUngroupingEnabled ? {ungroupBasedOnMetrics: values.isChannelUngrouped} : {}),
      },
    };

    if (action === CHANNEL_ACTION_TYPES.TEST) {
      return testChannelAsync({...payload, channelId: currentChannelId});
      // No need to handle response since it is handled by the Test component
    }

    const asyncAction = currentChannelId
      ? putChannelAsync({...payload, channelId: currentChannelId})
      : postChannelAsync(payload);
    const channelResponseErrors = await asyncAction;

    const tmpChannelId = !currentChannelId ? getPostChannel(store.getState())?.data?.id : currentChannelId;
    let accessListState = null;

    if (isRbacEnabled && tmpChannelId) {
      setAccesslist({
        channelId: tmpChannelId,
        groupsIds: accessGroups,
      });

      await setAccesslist;

      accessListState = isRbacEnabled ? getSetAccessListForChannels(store.getState()) : null;
    }

    await fetchChannelsAsync({refresh: true});

    if (channelResponseErrors) {
      warning({
        title: `Validation failed for ${channelsByValue[modalType].label} Channel`,
        description: `${channelResponseErrors.submitError}`,
      });
    } else if (accessListState && accessListState.error) {
      warning({
        title: `Failed to create access list`,
        description: `The channel was created but, it is open to every one`,
        settings: {
          uid: 'FailedToCreateAccessList',
        },
      });
    } else {
      success({
        settings: {
          autoDismiss: 5.5,
        },
        title: `${channelsByValue[modalType].label} Channel ${!currentChannelId ? 'Created' : 'Updated'} Successfully`,
        description: `"${values.channelName}".\r\n You can connect alerts to your channel in Alert Management`,
      });
    }

    if (action === CHANNEL_ACTION_TYPES.SALESFORCE_LOGIN) {
      if (tmpChannelId) {
        // This will switch the salesforce channel state from create to update, displaying different fields for update.
        setCurrentChannelId(tmpChannelId);
      }
    } else if (!currentChannelId) {
      onClose(tmpChannelId);
    } else {
      onClose();
    }
  };

  const initialValues = useMemo(
    () =>
      channel
        ? {
            channelName: channel.name,
            channelDataName: channel.channelData.channel,
            timeZone: channel.timezone,
            snowTable: channel.channelData.table,
            email: (channel.channelData.emailAddresses || [])
              .filter((item) => !(tags.users || []).includes(item))
              .map((item) => ({label: item, value: item})),
            members: (tags.users || []).map((item) => ({label: getUserName(users, item), value: item})),
            authentication: channel.channelData.authenticate,
            oktaClientId: channel.channelData.clientId,
            oktaIssuerUrl: channel.channelData.issuerUrl,
            oktaScope: channel.channelData.scope,
            integrationApiKey: '',
            accessToken: null,
            jiraIssueType: channel.channelData.issueType,
            jiraProjectId: channel.channelData.projectId,
            headers: channel.headers,
            jiraUser: channel.channelData.userEmail,
            user: channel.channelData.user,
            password: channel.authData && channel.authData.token,
            serviceKey: channel.channelData.serviceKey,
            topic: channel.channelData.topic,
            url:
              channel.channelData.url ||
              channel.channelData.jiraBaseURL ||
              (opsgenieSet.has(channel.channelData.baseUrl) ? '' : channel.channelData.baseUrl),
            urlDropdown: opsgenieSet.has(channel.channelData.baseUrl) ? channel.channelData.baseUrl : 'new',
            accountDimension: channel.channelData.accountDimension,
            isSalesforceSandboxChecked: channel.channelData.staging,
            salesforceUserName: channel.channelData.userName,
            salesforceLoginCode: channel.channelData.salesforceLoginCode,
          }
        : {
            members: [],
            email: [],
            urlDropdown: opsgenieOptions[0].value,
          },
    [channel],
  );

  const SettingsComponent = settingsComponentsMap[modalType];
  if (!SettingsComponent) {
    return null;
  }

  const getCreateAsyncButton = (callbacks, submitting, values, errors) => {
    let createAsyncButton;
    if (modalType === CHANNEL_TYPES.JIRA && !initialConnectionStatus) {
      createAsyncButton = (
        <AsyncButton
          isDisabled={!isEmpty(errors)}
          isLoading={values.action === CHANNEL_ACTION_TYPES.CREATE_INITIAL_CONNECTION && submitting}
          automationId="createInitialConnection"
          text="Create initial connection"
          onClick={callbacks.onInitialConnectionClick}
        />
      );
    } else if (modalType === CHANNEL_TYPES.SALESFORCE && !currentChannelId) {
      createAsyncButton = (
        <AsyncButton
          isDisabled={!isEmpty(errors)}
          isLoading={values.action === CHANNEL_ACTION_TYPES.SALESFORCE_LOGIN && submitting}
          automationId="createSalesforceConnection"
          text="Create connection"
          onClick={callbacks.onSalesforceLoginClick}
        />
      );
    } else {
      createAsyncButton = (
        <AsyncButton
          isDisabled={!isEmpty(errors)}
          isLoading={values.action === CHANNEL_ACTION_TYPES.SUBMIT && submitting}
          automationId="createChannel"
          text={`${currentChannelId ? 'Update' : 'Create'} Channel`}
          onClick={callbacks.onClick}
        />
      );
    }
    return createAsyncButton;
  };

  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={onClose}>
      <div className={classes.wrapper}>
        <Form initialValues={initialValues} onSubmit={saveChannel}>
          {({handleSubmit, values, submitFailed, errors, submitting, form: {change}}) => {
            const onClick = () => {
              change('action', CHANNEL_ACTION_TYPES.SUBMIT);
              handleSubmit();
            };
            const onInitialConnectionClick = () => {
              change('action', CHANNEL_ACTION_TYPES.CREATE_INITIAL_CONNECTION);
              handleSubmit();
            };
            const onSalesforceLoginClick = () => {
              change('action', CHANNEL_ACTION_TYPES.SALESFORCE_LOGIN);
              handleSubmit();
            };
            const Icon = channelsByValue[modalType].icon;
            return (
              <>
                <div className={classes.title}>
                  <Icon width={30} height={30} viewBox="0 0 50 50" />
                  <div className="ml_0-5">
                    {`${currentChannelId ? 'Edit' : 'New'} ${channelsByValue[modalType].label} Channel`}
                  </div>
                  {modalType === CHANNEL_TYPES.SLACKAPP && !currentChannelId && (
                    <i
                      role="presentation"
                      automation-id="closeNewSlackappModal"
                      className={`icon icn-general16-closea ${classes.closeModal}`}
                      onClick={() => onClose()}
                    />
                  )}
                </div>
                <div className={classes.settingsComponentWrapper}>
                  <SettingsComponent
                    channelType={modalType}
                    isCreate={!currentChannelId}
                    initialConnectionStatus={initialConnectionStatus}
                    channelId={currentChannelId}
                    isRbacEnabled={isRbacEnabled}
                    isChannelUngrouped={channel?.ungroupBasedOnMetrics}
                    onUpdateContainer={(groupsIds) => {
                      setAccessGroups(groupsIds);
                    }}
                  />
                </div>
                {submitFailed && values.action === CHANNEL_ACTION_TYPES.CREATE_INITIAL_CONNECTION && (
                  <div className="display_flex justifyContent_flex-end mb_1 fontWeight_500 fontSize_12">
                    <div className={classes.initialConnectionValidation}>
                      <i className="icon icn-general16-warning fontSize_16 color_red-500 mr_0-5 flexShrink_0" />
                      <div>Connection failed. Please check your Jira settings.</div>
                    </div>
                  </div>
                )}
                <div className={`display_flex justifyContent_space-between alignItems_center ${classes.footer}`}>
                  <a href={infoLinks[modalType]} target="_blank" rel="noopener noreferrer" className={classes.moreInfo}>
                    <i className="icon icn-general16-info mr_0-5" />
                    More Info
                  </a>
                  {modalType === CHANNEL_TYPES.SLACKAPP && !currentChannelId ? (
                    <div className="display_flex">
                      <AsyncButton
                        automationId="cancelNewSlackappModal"
                        text="Got it!"
                        isLoading={false}
                        onClick={() => onClose()}
                      />
                    </div>
                  ) : (
                    <div className="display_flex">
                      <div className="mr_1-5">
                        <AsyncButton
                          automationId="cancelNewChannel"
                          text="Cancel"
                          colorSchema="gray.300"
                          isLoading={false}
                          onClick={() => onClose()}
                        />
                      </div>
                      {getCreateAsyncButton(
                        {onClick, onSalesforceLoginClick, onInitialConnectionClick},
                        submitting,
                        values,
                        errors,
                      )}
                    </div>
                  )}
                </div>
              </>
            );
          }}
        </Form>
      </div>
    </Modal>
  );
};

export default NewEditChannel;
