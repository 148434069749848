// @flow
import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import * as commonSelectors from 'profile/store/selectors';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import {allowedPollingIntervalsByFileDatePattern} from 'bc/services/genericStorageService';
import {getAllowedRangeTypesByPollingInterval, getNotificationDelayOption} from 'bc/services/dataStreamService';
import Button, {COLORS} from 'common/componentsV2/Button';
import NotificationDelay from 'bc/components/streams/editor/scheduler/NotificationDelay';
import PollingInterval from 'bc/components/streams/editor/scheduler/PollingInterval';
import TimeZone from 'bc/components/streams/editor/scheduler/TimeZone';
import MaxMissingFiles from 'bc/components/streams/editor/scheduler/MaxMissingFiles';
import DateRange from 'bc/components/streams/editor/scheduler/DateRange';

import './S3SchedularModal.module.scss';

type PropTypes = {
  setSelectedStreamKeyVal: Function,

  dataStream: Object,
  onClose: Function,
  isS3ShortIntervalEnabled: Boolean,
  isBcUnlimitedFileUploadTimeRange: Boolean,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isS3ShortIntervalEnabled: commonSelectors.getIsS3ShortIntervalEnabled(state),
    isBcUnlimitedFileUploadTimeRange: commonSelectors.getBcUnlimitedFileUploadTimeRange(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)(
  class S3SchedularModal extends React.PureComponent {
    props: PropTypes;

    state = {
      pollingInterval: this.props.dataStream.pollingInterval,
      historicalDateRange: this.props.dataStream.historicalDateRange,
      timeZone: this.props.dataStream.timeZone,
      maxMissingFiles: this.props.dataStream.maxMissingFiles,
      notificationDelay: this.props.dataStream.notificationDelay,
      isTimeZoneAlertOpen: false,
    };

    onCloseButtonClicked = () => {
      this.props.onClose();
    };

    doNothing = () => {};

    pollingChanged = (val) => {
      this.setState({pollingInterval: val});
    };

    timeZoneChanged = (val) => {
      let isTimeZoneAlertOpen = false;
      let value = val;
      if (value === 'Browser') {
        value = moment.tz.guess();
      }
      if (this.props.dataStream.timeDefinition.timeZone && this.props.dataStream.timeDefinition.timeZone !== val) {
        isTimeZoneAlertOpen = true;
      }
      this.setState({
        isTimeZoneAlertOpen,
        timeZone: value,
      });
    };

    maxMissingFilesChanged = (val) => {
      this.setState({maxMissingFiles: val});
    };

    historicalDateRangeChanged = (val) => {
      this.setState({historicalDateRange: val});
    };

    notificationDelayChanged = (val) => {
      this.setState({notificationDelay: val});
    };

    onSave = () => {
      this.props.setSelectedStreamKeyVal({
        pollingInterval: this.state.pollingInterval,
        historicalDateRange: this.state.historicalDateRange,
        timeZone: this.state.timeZone,
        maxMissingFiles: this.state.maxMissingFiles,
        notificationDelay: this.state.notificationDelay,
      });
      this.onCloseButtonClicked();
    };

    getAllowedPollingIntervalValues = () => {
      if (this.props.isS3ShortIntervalEnabled && this.props.dataStream.fileNamePattern === 'yyyyMMddHHmm') {
        return ['daily', 'h12', 'h8', 'h6', 'h4', 'h3', 'h2', 'hourly', 'm15', 'm5', 'm1'];
      }
      return allowedPollingIntervalsByFileDatePattern[this.props.dataStream.fileNamePattern];
    };

    render() {
      return (
        <div styleName="modal-body">
          <div styleName="itemContainer">
            <PollingInterval
              pollingInterval={this.state.pollingInterval}
              allowedValues={this.getAllowedPollingIntervalValues()}
              onChange={this.pollingChanged}
            />
          </div>

          <div styleName="itemContainer">
            <DateRange
              allowedRangeTypes={getAllowedRangeTypesByPollingInterval(
                this.state.pollingInterval,
                this.props.isBcUnlimitedFileUploadTimeRange,
              )}
              dateRange={this.state.historicalDateRange}
              onChange={this.historicalDateRangeChanged}
              isUnix
            />
          </div>

          <div styleName="itemContainer">
            <MaxMissingFiles maxMissingFiles={this.state.maxMissingFiles} onChange={this.maxMissingFilesChanged} />
          </div>

          <div styleName="itemContainer">
            <TimeZone
              timeZone={this.state.timeZone}
              onChange={this.timeZoneChanged}
              warning={
                this.state.isTimeZoneAlertOpen
                  ? 'You have chosen a Timezone different than the data records’ timezone. This could result in ignored rows in the streaming process.'
                  : ''
              }
            />
          </div>

          <div styleName="itemContainer">
            <NotificationDelay
              notificationDelayOption={getNotificationDelayOption()}
              pollingInterval={this.state.pollingInterval}
              notificationDelay={this.state.notificationDelay}
              onChange={this.notificationDelayChanged}
            />
          </div>

          <div styleName="footer">
            <div styleName="left-button">
              <Button
                colorSchema={COLORS.GRAY_400}
                text="Cancel"
                automation-id="schedulerEditorModalCancel"
                onClick={this.onCloseButtonClicked}
              />
            </div>
            <div>
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Save"
                automation-id="schedulerEditorModalSave"
                onClick={this.onSave}
              />
            </div>
          </div>
        </div>
      );
    }
  },
);
