import {createSelector} from 'reselect';
import {get, omit} from 'lodash';
import {getCommon} from 'common/store/selectors';
import moment from 'moment';

export const getProfile = (state) => state?.profile;

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export const getProfileData = createSelector(
  getProfile,
  (profile) => profile?.data || EMPTY_OBJECT,
);

export const getUserProfile = createSelector(
  getProfileData,
  (profile) => profile.me || EMPTY_OBJECT,
);

export const getIsImpersonate = createSelector(
  getUserProfile,
  (me) => me.impersonatorId,
);

export const getIsNewMetricExplorer = createSelector(
  getUserProfile,
  (me) => get(me, 'organization.serverConfiguration.featureFlags.featuresActivation.ENABLE_NEW_METRIC_EXPLORER', false),
);

export const getLeanUserProfile = createSelector(
  getUserProfile,
  (me) => {
    const leanMe = omit(me, ['imageSrc', '__v', 'aptrinsicHmac', 'profiles', 'createdAt', 'appSettings', 'disabled']);
    if (me.organization) {
      leanMe.organization = omit(me.organization, ['settings']);
    }
    return leanMe;
  },
);

export const getIsMeLoading = createSelector(
  getUserProfile,
  (me) => me.isLoading,
);

export const getMeOwnerOrganization = createSelector(
  getUserProfile,
  (me) => me.ownerOrganization,
);

export const getQueryParamsView = createSelector(
  getProfileData,
  (profile) => profile.queryParamsView,
);

export const getProfileId = createSelector(
  getUserProfile,
  (me) => me._id,
);

export const getFirstName = createSelector(
  getUserProfile,
  (me) => me.firstName,
);

export const getLastName = createSelector(
  getUserProfile,
  (me) => me.lastName,
);

export const getInvitationId = createSelector(
  getUserProfile,
  (me) => me.invitationId || null,
);

export const getOrganization = createSelector(
  getUserProfile,
  (me) => me.organization || EMPTY_OBJECT,
);

export const getTimelineUserEventsStartTimeThreshold = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.timelineUserEventsStartTimeThreshold', EMPTY_OBJECT),
);

export const getActiveSteamsLimit = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.bcActiveSteamsLimit'),
);

export const getIncompleteSteamsLimit = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.bcIncompleteSteamsLimit'),
);

export const getFirstDayOfWeek = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.firstDayOfWeek'),
);

export const getMinInfluencingMetricsConditionRollup = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.minInfluencingMetricsConditionRollup') || 'long',
);

export const getRetentionsValues = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.retentions'),
);

export const getOrganizationSettings = createSelector(
  getOrganization,
  (org) => org.settings || EMPTY_OBJECT,
);

export const getOrganizationFeatureFlags = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation') || EMPTY_OBJECT,
);

export const getAdvanceSearchModeDefault = createSelector(
  getOrganizationSettings,
  (orgSettings) => get(orgSettings, 'composite.useAdvanceSearchMode', null),
);

export const getOrganizationSettingsBC = createSelector(
  getOrganizationSettings,
  (orgSettings) => orgSettings.bc || EMPTY_OBJECT,
);

export const getIsLookupEnabled = createSelector(
  getOrganizationSettingsBC,
  getOrganizationFeatureFlags,
  (bc, featureFlags) => bc.collectors.lookup || featureFlags.BC_ENABLE_COLLECTOR_LOOKUP || false,
);

export const getIsGAdsHourlyEnabled = createSelector(
  getOrganizationSettingsBC,
  getOrganizationFeatureFlags,
  (bc, featureFlags) => featureFlags.BC_ENABLE_COLLECTOR_GADS_HOURLY || false,
);

export const getIsS3ShortIntervalEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.BC_S3_SHORT_POOLING_INTERVAL', false),
);

export const getIsGAShortIntervalEnabled = createSelector(
  getOrganization,
  (org) =>
    get(org, 'serverConfiguration.featureFlags.featuresActivation.BC_GOOGLE_ANALYTICS_SHORT_POOLING_INTERVAL', false),
);

export const getIsStreamOwnerEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.BC_ENABLE_STREAM_OWNER', false),
);

export const getIsOnPremNewStreamEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.BC_ENABLE_ONPREM_NEW_STREAM', false),
);

export const getIsTimelineEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_TIMELINE', false),
);

export const getOrganizationMetricsLimit = createSelector(
  getOrganization,
  (org) => org.numberOfMetrics || 50000, // also in customer.js in main app
);

export const getOrganizationNoDataMinDuration = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.noDataMinDuration', 20 * 60),
);

export const getOrganizationNoDataMinCompositeDuration = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.noDataMinCompositeDuration', 60 * 60),
);

export const getEnableNewCompositeManagement = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_GET_ALERT_USAGE_IN_COMPOSITE', false),
);

export const getOrganizationMetricsLimitWithGrace = createSelector(
  getOrganization,
  getOrganizationMetricsLimit,
  (org, limit) => get(org, 'serverConfiguration.maxMetricsAllowed', limit * 1.2), // also in customer.js in main app
);

export const getOrganizationRollups = createSelector(
  getOrganization,
  getOrganizationMetricsLimit,
  (org) => get(org, 'serverConfiguration.rollups', EMPTY_ARRAY),
);

export const getIsAlertEnableAnomalyUpdate = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ALERTS_ENABLE_ANOMALY_UPDATE', false),
);

export const getIsEnableSimulationReferenceDate = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_SIMULATION_REFERENCE_DATE', false),
);

export const getChannelsDynamicRoutingEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_CHANNEL_DYNAMIC_ROUTING', false),
);

export const getAlertConsoleEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ALERT_CONSOLE_ENABLE_CONSOLE', false),
);

export const getNewAlertConsoleEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_NEW_ALERT_CONSOLE', false),
);

export const getNewAlertConsoleEnabledAndImpersonate = createSelector(
  getIsImpersonate,
  getNewAlertConsoleEnabled,
  (isImpersonating, isNewAlertConsoleEnabled) => isImpersonating && isNewAlertConsoleEnabled,
);

export const getNewAlertConsoleEnabledAndActive = createSelector(
  getOrganization,
  (org) =>
    get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_NEW_ALERT_CONSOLE', false) &&
    window.location.href.indexOf('alerts-console-new') >= 0,
);

// This flag is an indication whether the customer went through full migration to the new alert console
export const getNewAlertConsoleMigrationFinished = createSelector(
  getOrganization,
  (org) =>
    get(org, 'serverConfiguration.featureFlags.featuresActivation.ALERTS_SERVICE_ENABLE_DISPATCH_CHANNELS', false),
);

export const getMetricsStreamCubes = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.METRICS_STREAM_CUBES', false),
);

export const getBcUnlimitedFileUploadTimeRange = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.BC_UNLIMITED_FILE_UPLOAD_TIME_RANGE', false),
);

export const getAutoVolumeEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.VOLUME_CONDITION_ENABLED', false),
);

export const getRegressorsEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_REGRESSORS', false),
);

export const getRedshiftLimit = createSelector(
  getOrganization,
  (org) =>
    get(org, 'serverConfiguration.featureFlags.featuresActivation.BC_DISABLE_COLLECTOR_REDSHIFT_TABLE_DATA', false),
);

export const getInfluencingStaticEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_INFLUENCING_STATIC', false),
);

export const getDashboardV2Enabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.DASHBOARD_ENABLE_V2', false),
);

export const getDashboardV2PublicSharingEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_SHARING_PUBLIC_V2_DASHBOARDS', true),
);

export const getCreateDashboardV1Enabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_CREATE_DASHBOARD_V1', false),
);

export const getEnableNewMetricExplorer = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_NEW_METRIC_EXPLORER', false),
);

export const getEnableRefreshButtonMetricExplorer = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_REFRESH_BUTTON_METRIC_EXPLORER', false),
);

export const getInvestigationV2Enabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_INVESTIGATION_V2', false),
);

export const getFeaturesActivation = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation', EMPTY_OBJECT),
);

export const getOrganizationTimeZone = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.tz', null),
);

export const getTimeZoneDef = createSelector(
  getUserProfile,
  (me) => get(me, 'appSettings.timeZone.name', null),
);

export const getTimeZoneName = createSelector(
  getUserProfile,
  (me) => {
    const timeZoneName = get(me, 'appSettings.timeZone.name', null);
    return timeZoneName === 'Browser' || !timeZoneName ? moment.tz.guess() : timeZoneName;
  },
);

export const getShouldHideAdvanceMode = createSelector(
  getUserProfile,
  (me) => get(me, 'organization.settings.alertsConfiguration.shouldHideAdvanceMode'),
  (me) => get(me, 'organization.settings.alertsConfiguration.shouldHideAdvanceMode'),
);

export const getBucketStartTimeEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_BUCKET_START_TIME', false),
);

export const getTopologyEnabled = createSelector(
  getOrganization,
  getIsImpersonate,
  (o, impersonator) => {
    if (impersonator) {
      return true;
    }
    return get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_TOPOLOGY', false);
  },
);

export const getImpactEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_IMPACT', false),
);

export const getActivityLogEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_AUDIT_LOG', false),
);

export const getPieOrDonutEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_PIE_TILE', false),
);

export const getDashboardAnomalyTileEnabled = createSelector(
  getUserProfile,
  (me) => get(me, 'organization.serverConfiguration.featureFlags.featuresActivation.ENABLE_ANOMALIES_TILE', false),
);

export const getLabelsEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_LABELS', false),
);

export const getAlertConsoleEventsEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_ALERT_CONSOLE_EVENTS', false),
);

export const getHolidayHalloween = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_HOLIDAY_HALLOWEEN', false),
);
export const getHolidayChristmas = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_HOLIDAY_CHRISTMAS', false),
);

export const getHolidayStPatrickDay = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_HOLIDAY_ST_PATRICK_DAY', false),
);

export const getDvpConfigEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_DVP', false),
);

export const getAlertAutoAssignmentEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ALERT_AUTO_ASSIGNMENT', false),
);
export const getAlertAssigneeEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ALERT_ASSIGNEE', false),
);

export const getTimeFrameV2Enabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_NEW_TIME_FRAME', false),
);

export const getForecastEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_FORECAST', false),
);

export const getSlackappEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_SLACKAPP_CHANNEL', false),
);

export const getMattermostEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_MATTERMOST_CHANNEL', false),
);

export const getServiceNowEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_SERVICENOW_CHANNEL', false),
);

export const getTelegramEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_TELEGRAM_CHANNELL', false),
);

export const getSalesforceEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_SALESFORCE_CHANNEL', false),
);

export const getEnableFeedbackNodataStatic = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_FEEDBACK_NODATA_STATIC', false),
);

export const getEnableMultipleEvents = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_MULTIPLE_EVENTS', false),
);

export const getEnableEventsStreams = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.BC_ENABLE_EVENTS_STREAMS', false),
);

export const getEnableLogoUA = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_LOGO_UA', false),
);

export const getChannelUngroupingEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.UNGROUP_MESSAGES_BASED_ON_METRIC', false),
);

export const getStaticNoDataTriageEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_STATIC_NO_DATA_TRIAGE', false),
);

export const getTriageFullScreenEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_TRIAGE_FULLSCREEN', false),
);

export const getEmbeddedValueReportEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_EMBEDDED_VALUE_REPORT', false),
);

export const getRecommendationsEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_REVENUE_RECOMMENDATIONS', false),
);

export const getHolidayEnable = createSelector(
  getHolidayHalloween,
  getHolidayChristmas,
  getHolidayStPatrickDay,
  (isHalloweenEnable, isChristmasEnabled, isStPatrickDayEnable) =>
    isHalloweenEnable || isChristmasEnabled || isStPatrickDayEnable,
);

export const getClassNameHoliday = createSelector(
  getHolidayHalloween,
  getHolidayChristmas,
  getHolidayStPatrickDay,
  (isHalloweenEnable, isChristmasEnabled, isStPatrickDayEnable) => {
    if (isHalloweenEnable) {
      return 'halloween';
    }
    if (isChristmasEnabled) {
      return 'christmas';
    }
    if (isStPatrickDayEnable) {
      return 'stPatrickDay';
    }

    return '';
  },
);

export const getMeAppSettings = createSelector(
  getUserProfile,
  (me) => me.appSettings || EMPTY_OBJECT,
);

export const getIsOnBoardingDismiss = createSelector(
  getMeAppSettings,
  (appSettings) => appSettings.isOnBoardingDismiss,
);

export const isReadOnlyUser = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles[0].indexOf('-read-only') !== -1,
);

export const isAnodot = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles[0].indexOf('anodot-') !== -1,
);

export const isAnodotUser = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles[0].indexOf('anodot-user') !== -1,
);

export const isAnodotAdmin = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles.includes('anodot-admin'),
);

export const getRoles = createSelector(() => [
  {role: 'anodot-admin', name: 'Anodot Admin'},
  {role: 'anodot-user', name: 'Anodot User'},
  {role: 'customer-admin', name: 'Admin'},
  {role: 'customer-user', name: 'User'},
  {role: 'customer-read-only', name: 'Read Only'},
]);

export const isCustomerAdmin = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles.includes('customer-admin'),
);

export const isCustomerUser = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles.includes('customer-user'),
);

export const getAllowedRoles = createSelector(
  getRoles,
  isAnodot,
  (allRoles, isAndt) => {
    if (isAndt) {
      return allRoles;
    }
    return allRoles.filter((r) => r.role.indexOf('customer-') !== -1);
  },
);

export const getIsAdminUser = createSelector(
  isAnodotAdmin,
  isCustomerAdmin,
  (isAnodotAdministrator, isCustomerAdministrator) => isAnodotAdministrator || isCustomerAdministrator,
);

export const getRoleName = createSelector(
  getUserProfile,
  getRoles,
  (me, roles) => {
    const r = roles.find((i) => me.roles && i.role === me.roles[0]);
    return r && r.name;
  },
);

export const getPageVisited = createSelector(
  getMeAppSettings,
  (appSettings) => appSettings.pageVisited || [],
);

export const getShowMandatoryRenameModal = createSelector(
  getMeAppSettings,
  (appSettings) => get(appSettings, 'usersGroups.isRenameNeeded', false),
);

export const getIsUsersGroupsFiltersBarOpen = createSelector(
  getMeAppSettings,
  (appSettings) => get(appSettings, 'usersGroups.isFiltersBarOpen', true),
);

export const getIsCustomersFiltersBarOpen = createSelector(
  getMeAppSettings,
  (appSettings) => get(appSettings, 'customers.isFiltersBarOpen', true),
);

export const getIsAssetsFiltersBarOpen = createSelector(
  getMeAppSettings,
  (appSettings) => get(appSettings, 'assets.isFiltersBarOpen', true),
);

export const getFeatureOnBoarding = createSelector(
  getOrganization,
  getMeAppSettings,
  getShowMandatoryRenameModal,
  (org, appSettings, isRenameNeeded) => {
    const featureFlag = get(
      org,
      'serverConfiguration.featureFlags.featuresActivation.ENABLE_FIRST_TIME_USER_EXPERIENCE',
      false,
    );
    const {newToFtue} = appSettings;
    return featureFlag && newToFtue && !isRenameNeeded;
  },
);

export const getPagesVisited = createSelector(
  getMeAppSettings,
  (appSettings) => appSettings.pageVisited || EMPTY_ARRAY,
);

export const getPayingCustomer = createSelector(
  getOrganizationSettings,
  (i) => get(i, 'payingCustomer', false),
);

export const getSelfService = createSelector(
  getOrganizationSettings,
  (i) => get(i, 'sierra.selfService', false),
);

export const getSelfServicePackage = createSelector(
  getOrganizationSettings,
  (i) => get(i, 'sierra.selfServicePackage', false),
);

export const getInsightsPanel = createSelector(
  getMeAppSettings,
  (appSettings) => appSettings.insightsPanel || EMPTY_OBJECT,
);

export const getPredefinedDateUserSetting = createSelector(
  getMeAppSettings,
  (appSettings) => appSettings.predefinedDateSettings || EMPTY_OBJECT,
);

export const userSettingIsLoading = createSelector(
  getCommon,
  (common) => get(common, ['outstandingLoadingBarRequests', 'anodot/profile/UPDATE_USER_SETTINGS']),
);

export const getOrgTopologySetting = createSelector(
  getOrganizationSettings,
  (orgSettings) => orgSettings.topology || EMPTY_OBJECT,
);
