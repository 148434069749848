/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-template */
import {palette} from 'app/styles/theme';

export const EVENTS_PLOT_LINE_ID = 'simulation-user-events';
export const EVENT_PLOT_LINE_COLOR = {
  normal: 'transparent',
  hover: palette.teal[600],
};

export const getPlotLineOptions = (eventData, uniqueId, isSelected) => {
  const selectedClass = isSelected ? 'selected' : '';
  const label = {
    text:
      '<i style="position: absolute; top: -48px; left:-4px; font-size: 16px"' +
      `class="icon icn-general16-marker andt-user-events-icon ${selectedClass}"` +
      'data-plot-line-id="' +
      uniqueId +
      '"><span class="andt-user-events-icon-mask"/>' +
      '</i>',
    verticalAlign: 'bottom',
    textAlign: 'center',
    y: 50,
    x: -4,
    useHTML: true,
    rotation: 0,
  };

  return {
    id: EVENTS_PLOT_LINE_ID,
    className: `${EVENTS_PLOT_LINE_ID} ${uniqueId}`,
    color: EVENT_PLOT_LINE_COLOR.normal,
    width: 2,
    value: eventData.date * 1000,
    zIndex: 1, // get over the grid lines
    label,
  };
};

export const getEventsFilteredByMatchingProperties = (events, alertEventsFilters, metrics) => {
  let calculatedAlertEvents = [];
  for (let i = 0; i < alertEventsFilters?.length; i++) {
    const filterEvents = events?.filter((evs) => evs.eventId === alertEventsFilters[i].id);

    const filteredFilteredEvents = filterEvents.filter((e) => {
      let tmpEvents = e?.topEvents ? [...e.topEvents] : [];
      for (let j = 0; j < alertEventsFilters[i]?.eventMetricFilter?.length; j++) {
        const {eventProperty} = alertEventsFilters[i].eventMetricFilter[j];
        const {metricDimension} = alertEventsFilters[i].eventMetricFilter[j];
        // For each matching property - filter the events. Assumption - AND between matching properties, i.e. event has to have all the correct matching properties values.
        const metricToFind = metrics?.find((m) => m.key === metricDimension);
        if (metricToFind) {
          tmpEvents = tmpEvents.filter((ev) =>
            ev.properties.find((prop) => prop.key === eventProperty && prop.value === metricToFind.value),
          );
        }
      }
      return tmpEvents?.length > 0;
    });

    calculatedAlertEvents = calculatedAlertEvents.concat(filteredFilteredEvents);
  }
  return calculatedAlertEvents;
};
